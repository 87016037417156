<template>
  <AtomSectionHeader title="Support" />
  <main>
    <div class="mx-auto px-4 py-4 max-w-7xl sm:px-6 lg:px-8 lg:py-12">
      <div class="px-4 py-6 sm:px-0">
        <div class="h-96 border-4 border-dashed border-black rounded-lg"></div>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    data() {
      return {}
    },
    created() {
      console.log('Support')
    }
  }
</script>
