<template>
  <AtomSectionHeader classes="md:px-4 lg:px-8" :title="title" />

  <main v-if="loggedInUser">
    <div class="mx-auto px-4 py-4 max-w-7xl sm:px-6 lg:px-8 lg:py-12">
      <Form
        :validation-schema="formSchema.validation"
        :initial-values="formSchema.values"
        class="divide-gray divide-y space-y-8"
        @submit="submitForm"
      >
        <div class="divide-gray divide-y space-y-8 sm:space-y-5">
          <div>
            <div>
              <h3 class="text-gray-900 text-lg font-medium leading-6">Update your password</h3>
              <p class="text-gray-500 mt-1 max-w-2xl text-sm">Please ensure you are using a strong password</p>
            </div>
            <div class="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
              <div v-for="field in formSchema.fields" :key="field.id" :class="field.classes">
                <div
                  class="sm:grid sm:gap-4 sm:grid-cols-3 sm:items-start"
                  :class="[!field.combinedFields ? 'sm:border-t sm:border-gray sm:pt-5' : '']"
                >
                  <label for="field.id" class="text-gray-700 block text-sm font-medium sm:mt-px sm:pt-2">
                    {{ field.label }} <span v-if="field.isRequired">*</span>
                  </label>
                  <div class="mt-1 sm:mt-0" :class="[field.wide ? 'sm:col-span-2' : '']">
                    <Field
                      :id="field.id"
                      :name="field.id"
                      :as="field.elem"
                      :type="field.type"
                      :rules="field.isRequired"
                      class="
                        block
                        w-full
                        max-w-lg
                        border-gray
                        focus:border-orange-500
                        rounded-sm
                        focus:ring-orange-500
                        sm:text-sm
                      "
                      :class="[field.wide ? 'max-w-lg' : 'sm:max-w-xs']"
                      v-bind="attrs"
                    >
                      <template v-if="field.children && field.children.length">
                        <component
                          :is="tag"
                          v-for="({ tag, text, ...childAttrs }, idx) in field.children"
                          :key="idx"
                          v-bind="childAttrs"
                        >
                          {{ text }}
                        </component>
                      </template>
                    </Field>
                    <ErrorMessage :name="field.id" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5">
          <div class="sm:flex sm:justify-end">
            <RouterLink
              to="/account/settings"
              class="
                hover:bg-gray-50
                inline-flex
                items-center
                justify-center
                mb-4
                px-4
                py-2
                w-full
                text-black text-base
                font-medium
                leading-6
                bg-gray
                border border-gray
                rounded-sm
                focus:outline-none
                transition
                duration-150
                ease-in-out
                sm:mb-0
                sm:w-auto
              "
            >
              <span>Cancel</span>
            </RouterLink>

            <button
              type="submit"
              :class="[
                'w-full sm:w-44 sm:ml-4 inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-sm text-white bg-orange-500 focus:outline-none focus:border-orange-500 active:bg-orange-300 transition ease-in-out duration-150',
                !isLoading ? 'hover:bg-orange-300 ' : 'bg-orange-300 cursor-default'
              ]"
            >
              <span v-if="!isLoading">Change password</span>
              <AtomLoader v-else></AtomLoader>
            </button>
          </div>
        </div>
      </Form>
    </div>
  </main>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { Field, Form, ErrorMessage } from 'vee-validate'
  import * as yup from 'yup'

  export default {
    components: {
      ErrorMessage,
      Field,
      Form
    },
    data() {
      return {
        isLoading: false,
        title: 'Security'
      }
    },
    computed: {
      ...mapGetters({
        loggedInUser: 'auth/getLoggedInUser'
      }),
      formSchema() {
        const user = this.loggedInUser

        return {
          fields: [
            {
              id: 'password',
              label: 'Current password',
              elem: 'input',
              type: 'password',
              isRequired: 'true',
              wide: true,
              combinedFields: true
            },
            {
              id: 'newPassword',
              label: 'New password',
              elem: 'input',
              type: 'password',
              isRequired: 'true',
              wide: true,
              combinedFields: true
            },
            {
              id: 'newPasswordConfirmation',
              label: 'Confirm new password',
              elem: 'input',
              type: 'password',
              isRequired: 'true',
              wide: true,
              combinedFields: true
            }
          ],
          validation: yup.object().shape({
            password: yup.string().required().min(6).label('Current password'),
            newPassword: yup.string().required().min(6).label('New password'),
            newPasswordConfirmation: yup
              .string()
              .required('Please confirm your new password')
              .oneOf([yup.ref('newPassword')], 'Passwords do not match')
          })
        }
      }
    },
    methods: {
      async submitForm(values, { resetForm }) {
        this.isLoading = true

        try {
          this.$store.dispatch('auth/changePassword', values)
        } catch (e) {
          if (e) console.log(`Error changing password: ${e.status} ${e.data}`)
        }

        this.isLoading = false
      }
    }
  }
</script>
