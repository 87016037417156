<template v-if="!isLoading">
  <div class="mb-4 lg:mb-8">
    <MoleculeCover
      :cover-photo-url="coverPhotoUrl"
      action-title="Edit album"
      :is-owner="isOwner || (loggedInUser && loggedInUser.admin)"
      @show-panel="buildForm()"
    />

    <div class="mb-4 divide-gray divide-y lg:mb-8">
      <div class="pb-4 w-full md:px-4 lg:pb-8 lg:px-8">
        <AtomSectionHeader
          v-if="album"
          :title="album.name"
          icon="CollectionIcon"
          action-title="Change order"
          :owner-action="true"
          :is-owner="isOwner || (loggedInUser && loggedInUser.admin)"
          is-full-width
          @action="isDraggable = !isDraggable"
        >
          <template #filter>
            <button
              class="
                group
                lg:hover:bg-orange-300
                lg:hover:border-orange-300
                lg:hover:text-white
                inline-flex
                items-center
                justify-center
                mt-2
                h-10
                text-black text-sm
                font-medium
                bg-gray
                border-0
                rounded-sm
                focus:outline-none
                overflow-hidden
                transform
                transition
                duration-300
                ease-in-out
                sm:mt-0
              "
              :class="[showLikedPhotographs ? 'bg-orange-500 text-white' : 'bg-gray text-black']"
              :title="[showLikedPhotographs ? 'Show all photos' : 'Show liked photos']"
              @click.prevent="togglePhotographs"
            >
              <BaseIcon class="flex-shrink-0 mx-2 w-7 h-7">
                <LoveIcon />
              </BaseIcon>
              <p class="mr-4">
                Showing: <template v-if="showLikedPhotographs">Liked</template
                ><template v-if="!showLikedPhotographs">All</template> photos
              </p>
            </button>
          </template>
          <button
            v-if="isOwner || isAdmin"
            class="
              group
              lg:hover:bg-orange-300
              lg:hover:border-orange-300
              inline-flex
              items-center
              justify-center
              mt-2
              h-10
              text-white text-sm
              font-medium
              bg-orange-500
              border-0
              rounded-sm
              focus:outline-none
              overflow-hidden
              transform
              transition
              duration-300
              ease-in-out
              sm:mt-0
            "
            title="Upload photos"
            @click.prevent="toggleUpload"
          >
            <BaseIcon class="flex-shrink-0 mx-2 w-7 h-7 transform transition-all duration-300 ease-in-out">
              <UploadIcon />
            </BaseIcon>
            <p class="mr-4 transition-all duration-300 ease-in-out">Upload photos</p>
          </button>
        </AtomSectionHeader>

        <AtomFilePond
          v-if="(isOwner || (loggedInUser && loggedInUser.admin)) && showUpload"
          class="mx-4 my-8 md:mx-0"
          title="Select files or drag &amp; drop here"
          :multiple="true"
          :folder="uploadFolder"
          :album="album"
          @upload-complete="refreshPhotographs"
          @uploading="isSaving = true"
        />

        <div class="mt-4 px-4 md:px-0 lg:mt-8">
          <div v-if="hasLikedPhotographs && showLikedPhotographs" class="text-center">
            <div class="relative grid gap-2 2xl:grid-cols-4 grid-cols-none lg:grid-cols-2 xl:grid-cols-3">
              <template v-for="element in likedPhotographs" :key="element.uid">
                <div class="relative block cursor-default">
                  <AtomPhotograph
                    :photograph="element"
                    :event="album.event_uid"
                    :album-uid="album.uid"
                    :link="true"
                    :cover-photo="element.cover_photo"
                    :hide-actions="isDraggable"
                    :organisers-album="album.organisers_album"
                    @deleted="updatePhotographList"
                    @liked="updateLikedPhotographList"
                    @updated="setCoverPhotograph"
                  />
                </div>
              </template>
            </div>
          </div>
          <p
            v-if="showLikedPhotographs && isFiltering"
            class="flex text-black text-2xl font-bold tracking-tight leading-tight"
          >
            Loading…
          </p>
          <p v-if="!hasLikedPhotographs && showLikedPhotographs && !isFiltering">
            You haven't liked any photographs in this album.
          </p>

          <div v-if="hasPhotographs && !showLikedPhotographs" class="text-center">
            <div>
              <draggable
                v-model="photographs"
                :disabled="!isDraggable"
                class="relative grid gap-2 2xl:grid-cols-4 grid-cols-none lg:grid-cols-2 xl:grid-cols-3"
                item-key="key"
                @change="onChange"
              >
                <template v-if="isOwner || (loggedInUser && loggedInUser.admin)" #header>
                  <div class="flex 2xl:col-span-4 w-full lg:col-span-2 xl:col-span-3">
                    <button
                      v-if="isDraggable"
                      :disabled="isSavingOrder"
                      type="submit"
                      :class="[
                        'flex w-full items-center justify-center px-6 py-3 rounded-sm border border-transparent text-base leading-6 font-medium text-white bg-orange-500 focus:outline-none focus:border-orange-500 focus:shadow-outline-red active:bg-orange-300 transition ease-in-out duration-150',
                        !isSavingOrder ? 'hover:bg-orange-300 ' : 'bg-orange-300 cursor-default'
                      ]"
                      @click="saveOrder"
                    >
                      <span v-if="!isSavingOrder">Save order</span>
                      <AtomLoader v-else></AtomLoader>
                    </button>
                    <button
                      v-if="isDraggable"
                      type="cancel"
                      :class="[
                        'flex w-full items-center justify-center px-6 py-3 rounded-sm border border-transparent text-base leading-6 font-medium text-black bg-gray focus:outline-none focus:border-gray focus:shadow-outline-red active:bg-gray transition ease-in-out duration-150',
                        !isLoading ? 'hover:bg-gray ' : 'bg-gray cursor-default'
                      ]"
                      @click="resetOrder"
                    >
                      <span>Reset order</span>
                    </button>
                  </div>
                </template>

                <template #item="{ element }">
                  <div :class="['block relative', isDraggable ? 'cursor-move' : 'cursor-default']">
                    <AtomPhotograph
                      :classes="[isDraggable ? 'pointer-events-none' : 'pointer-events-auto']"
                      :photograph="element"
                      :event="album.event_uid"
                      :album-uid="album.uid"
                      :link="true"
                      :cover-photo="element.cover_photo"
                      :hide-actions="isDraggable"
                      :organisers-album="album.organisers_album"
                      @deleted="updatePhotographList"
                      @updated="setCoverPhotograph"
                    />
                  </div>
                </template>
              </draggable>
              <button
                v-if="total > visible"
                type="submit"
                :class="[
                  'flex mx-auto mt-4 items-center justify-center border border-gray w-full lg:w-auto py-2 px-4 rounded-sm transition-colors ease-in-out duration-300 lg:hover:bg-orange-500 lg:hover:text-white lg:hover:border-orange-500 focus:outline-none focus:ring-orange-500 focus:border-orange-500',
                  !isLoadingNextPage ? 'lg:hover:bg-orange-300' : 'bg-orange-500 cursor-default'
                ]"
                @click.prevent="getNextPage()"
              >
                <span v-if="!isLoadingNextPage" class="pointer-events-none"> Show more </span>
                <AtomLoader v-else></AtomLoader>
              </button>
            </div>
          </div>
          <p v-if="!hasPhotographs && !showLikedPhotographs">No photographs found.</p>
        </div>
      </div>

      <section v-if="album" class="mt-4 lg:mt-0">
        <AtomSectionHeader classes="md:px-4 lg:px-8" title="Comments" heading-tag="h2" />
        <OrganismComments
          :comments="comments"
          :author-uid="album.owner_uid"
          :notifications-disabled="album.organisers_album"
          :content-uid="album.uid"
          @update-comments="updateCommentList"
        />
      </section>
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'
  import AtomFilePond from '@/components/atoms/FilePond.vue'
  import draggable from 'vuedraggable'

  export default {
    components: {
      AtomFilePond,
      draggable
    },
    inject: ['sidebar'],
    data() {
      return {
        actionTitle: 'Update album',
        isLoadingNextPage: false,
        isImageLoaded: false,
        photographs: null,
        likedPhotographs: null,
        photographsDefault: null,
        page: 1,
        offset: 0,
        total: 0,
        visible: 0,
        perPage: 24,
        name: null,
        isSaving: false,
        isSavingOrder: false,
        album: null,
        comments: null,
        isDraggable: false,
        isFiltering: false,
        cover: null,
        showUpload: false,
        showLikedPhotographs: false
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        panelStatus: 'getPanelStatus',
        isLoading: 'getLoadingStatus',
        loggedInUser: 'auth/getLoggedInUser',
        allEvents: 'events/getEvents',
        today: 'getToday'
      }),
      isOwner() {
        return this.album && this.loggedInUser ? this.album.owner_uid === this.loggedInUser.id : false
      },
      numPhotographs() {
        const str = this.album.results !== 1 ? 'photographs' : photograph
        return `${this.album.results} ${str}`
      },
      hasPhotographs() {
        return !this.isLoading && !!this.photographs
      },
      hasLikedPhotographs() {
        return !this.isLoading && !!this.likedPhotographs
      },
      uploadFolder() {
        return this.album ? `albums/${this.album.uid}` : null
      },
      isAdmin() {
        return this.loggedInUser && this.loggedInUser.admin
      },
      form() {
        if (this.album) {
          const date = this.album.date.split('-')
          const formattedDate = `${date[0]}-${date[1]}-${date[2].slice(0, 2)}`

          return {
            cta: 'Update album',
            classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
            initial: {
              name: this.album.name,
              date: formattedDate
            },
            fields: [
              {
                id: 'name',
                label: 'Name',
                elem: 'input',
                type: 'text',
                isRequired: 'true',
                classes: 'sm:col-span-2'
              },
              {
                id: 'event_uid',
                label: 'Event',
                elem: 'select',
                isRequired: null,
                classes: 'sm:col-span-2',
                children: []
              },
              {
                id: 'date',
                label: 'Date',
                elem: 'input',
                type: 'date',
                isRequired: 'true',
                classes: 'sm:col-span-2'
              }
            ]
          }
        }
        return null
      },
      coverPhotoUrl() {
        return this.cover
          ? `${process.env.VUE_APP_IMGIX}/${this.cover}`
          : this.album && this.album.cover
          ? `${process.env.VUE_APP_IMGIX}/albums/${this.album.uid}/${this.album.cover}`
          : null
      }
    },
    watch: {
      async panelStatus(res) {
        if (res) this.loadContent()
      },
      '$route.query.u'() {
        if (this.$route.query && this.$route.query.u) this.showPhotograph(this.$route.query.u)
      }
    },
    updated() {
      if (this.album && this.$route.params.albumUid !== this.album.uid) this.loadContent()
    },
    mounted() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    created() {
      this.loadContent()

      if (this.$route.query.created) this.showCreatedDialog()
    },
    methods: {
      async loadContent() {
        this.$store.dispatch('setLoadingStatus', true)
        await this.getAlbumByUid()
        await Promise.all([this.getAlbumPhotographs(), this.getComments()])
        if (this.$route.query.u) this.showPhotograph(this.$route.query.u)
        this.$store.dispatch('setLoadingStatus', false)
      },
      async getAlbumByUid(
        albumUid = this.$route.params.albumUid,
        page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
      ) {
        const options = {}

        if (page) this.page = page

        try {
          this.album = await this.apiRequest(`/album/${albumUid}`, null, null, {
            Authorization: this.userJWT
          })

          this.total = this.album.results
        } catch (e) {
          this.$router.push({ name: 'NotFound' })
          if (e) console.log(`Error getting album: ${e.status} ${e.data}`)
        }
      },
      async getAlbumPhotographs(forceRefresh) {
        if (this.total > 0 || forceRefresh) {
          this.photographs = await this.apiRequest(
            `/album/${this.album.uid}/photographs`,
            null,
            { page: forceRefresh ? 1 : this.page, perPage: this.perPage },
            {
              Authorization: this.userJWT
            }
          )

          this.photographsDefault = this.photographs

          this.offset = parseInt(this.photographs[this.photographs.length - 1].id) + 1
          this.page += 1
        }

        this.visible = this.total > this.perPage ? this.perPage : this.total
      },
      async getAlbumLikedPhotographs() {
        this.isFiltering = true

        this.likedPhotographs = await this.apiRequest(`/album/${this.album.uid}/photographs/liked`, null, null, {
          Authorization: this.userJWT
        })

        this.isFiltering = false
      },
      async getComments() {
        const headers = {}

        if (this.userJWT) headers.Authorization = this.userJWT

        try {
          this.comments = await this.apiRequest('/comment/comments', null, { u: this.album.uid }, headers)
        } catch (e) {
          if (e) console.log(`Error getting comments: ${e.status} ${e.data}`)
        }
      },
      async getNextPage() {
        const cta = event.target

        if (cta && cta.style) cta.style.width = `${cta.offsetWidth}px`
        this.isLoadingNextPage = true

        try {
          const photographs = await this.apiRequest(
            `/album/${this.$route.params.albumUid}/photographs`,
            null,
            { page: this.page, perPage: this.perPage },
            {
              Authorization: this.userJWT
            }
          )

          this.photographs = this.photographs.concat(photographs)
          this.offset = parseInt(this.photographs[this.photographs.length - 1].id) + 1
          this.page += 1
        } catch (e) {
          if (e) console.log(`Error getting photographs: ${e.status} ${e.data}`)
        }

        this.visible += this.perPage
        if (cta && cta.style) cta.style.width = null
        this.isLoadingNextPage = false
      },
      async editAlbum(values) {
        this.isSaving = true

        try {
          const response = await this.apiRequest(
            `/album/${this.album.uid}`,
            values,
            null,
            { Authorization: this.userJWT },
            'PUT'
          )

          this.$store.dispatch('setDialog', {
            title: 'Album Updated!',
            content: `<p>Thanks for editing ${values.name}.</p>`,
            ctaTitle: 'Close',
            confirmed: true
          })

          this.getAlbumByUid(this.album.uid, 1)
        } catch (e) {
          if (e) console.log(`Error editing album: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: `<p>Sorry we couldn't update ${this.album.name}. Please try again.</p>`,
            ctaTitle: 'Close',
            error: true
          })
        }

        this.isSaving = false
      },
      async refreshPhotographs() {
        await Promise.all([this.getAlbumPhotographs(true)])
      },
      async updateLikedPhotographList(photographUid) {
        this.likedPhotographs = this.likedPhotographs.filter((photograph) => {
          return photograph.uid !== photographUid
        })
      },
      async updatePhotographList(photographUid) {
        console.log('Removing photograph UID', photographUid)

        // this.photographs = this.photographs.filter((photograph) => {
        //   return photograph.uid !== photographUid
        // })

        // this.album.results -= 1
      },
      async setCoverPhotograph(photographUid) {
        this.photographs.forEach((photograph) => {
          if (photograph.uid === photographUid) {
            photograph.cover_photo = true
            this.cover = photograph.path
          } else {
            photograph.cover_photo = false
          }
        })
      },
      async saveOrder() {
        this.isSavingOrder = true

        const order = this.photographs.map((p) => {
          return {
            id: p.id,
            uid: p.uid
          }
        })

        try {
          await this.apiRequest(`/album/${this.album.uid}/order`, order, null, { Authorization: this.userJWT }, 'PUT')
        } catch (e) {
          if (e) console.log(`Error updating order: ${e}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: `<p>Sorry we couldn't update the album order. Please try again.</p>`,
            ctaTitle: 'Close',
            error: true
          })
        }

        await this.getAlbumByUid()
        this.isSavingOrder = false
        this.isDraggable = false
      },
      async buildForm() {
        // Reset form prop
        for await (const field of this.form.fields) {
          field.children = []
        }

        await Promise.all([this.getEvents()])

        this.setEvents()
        this.setMaxDate()

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          method: 'PUT',
          endpoint: `/album/${this.album.uid}`,
          errorMessage: "Sorry we couldn't edit the album.",
          successMessage: 'Your album has been updated.'
        })
      },
      async getEvents() {
        try {
          if (this.loggedInUser.admin) await this.$store.dispatch('events/getEvents', { all: true })
          if (!this.loggedInUser.admin)
            this.events = await this.apiRequest(
              `/user/${this.loggedInUser.id}/events`,
              null,
              { basic: true, start: '2000-01-01', end: this.today },
              {
                Authorization: this.userJWT
              }
            )
        } catch (e) {
          if (e) console.log('Error getEvents')
        }
      },
      async setEvents() {
        const events = this.loggedInUser.admin ? this.allEvents.events.reverse() : this.events
        const eventFormField = this.form.fields.find((field) => field.id === 'event_uid')
        const pastEvents = events.filter((event) => {
          return Date.parse(event.start_date) <= Date.now() ? event : null
        })
        const re = /(\d{4})[-. \/](\d\d)[-. \/](\d\d)/

        eventFormField.children.push({
          tag: 'option',
          text: 'None',
          value: 'none'
        })

        pastEvents.forEach((event) => {
          const date = event.start_date.slice(0, 10).replace(re, '$3/$2/$1')
          let text = `${date} - ${event.circuit} `

          if (event.slug.includes('evening')) text += `- Evening `
          text += `(${event.organiser})`

          eventFormField.children.push({
            tag: 'option',
            text,
            value: event.uid
          })
        })

        eventFormField.selected = this.album.event_uid ? this.album.event_uid : 'none'
      },
      async setMaxDate() {
        const date = this.form.fields.find((field) => field.id === 'date')

        date.max = this.today
      },
      async showPhotograph(photographUid) {
        let photograph = this.photographs
          ? this.photographs.find((photograph) => photograph.uid === photographUid)
          : null

        if (!!!photograph) {
          try {
            photograph = await this.apiRequest(`/photograph/${photographUid}`, null, null, {
              Authorization: this.userJWT
            })
          } catch (e) {
            if (e) console.log(`Error getting photograph: ${e.status} ${e.data}`)
          }
        }

        this.$store.dispatch('setOverlay', {
          imagePath: `${process.env.VUE_APP_IMGIX}/${photograph.path}?w=1920&ar=1.5`,
          uid: photographUid,
          photograph_uid: photographUid,
          url: photographUid,
          liked: photograph.liked
        })
      },
      async updateCommentList(commentUid) {
        if (commentUid) {
          this.comments = this.comments.filter((comment) => {
            return comment.uid !== commentUid
          })
        } else {
          this.getComments()
        }
      },
      resetOrder() {
        this.isDraggable = false
        this.photographs = this.photographsDefault
      },
      onChange(evt) {
        this.photographs.forEach((photograph, index) => {
          photograph.id = index + 1
        })
      },
      showCreatedDialog() {
        this.$store.dispatch('setDialog', {
          title: this.$route.query.flickrAlbumUid ? 'Importing Album!' : 'Album Created!',
          content: this.$route.query.flickrAlbumUid
            ? '<p>Please come back in a few minutes to see all of the imported photographs.</p>'
            : '<p>Thanks for creating a new album. You can now add photographs to it.</p>',
          ctaTitle: 'Close',
          confirmed: true
        })

        this.$router.replace({ query: null })
      },
      toggleUpload() {
        this.showUpload = !this.showUpload
      },
      imageLoaded() {
        this.isImageLoaded = true
      },
      togglePhotographs() {
        this.showLikedPhotographs = !this.showLikedPhotographs

        // if (this.showLikedPhotographs && !this.hasLikedPhotographs) {
        if (this.showLikedPhotographs) {
          this.getAlbumLikedPhotographs()
        }
      }
    }
  }
</script>
