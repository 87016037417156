<template>
  <MoleculeText title="About us">
    <template #intro>
      <p class="text-gray-500 mt-4 text-xl leading-8">
        Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget
        aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend
        egestas fringilla sapien.
      </p>
    </template>
    <template #default>
      <p>
        Faucibus commodo massa rhoncus, volutpat.
        <strong>Dignissim</strong> sed <strong>eget risus enim</strong>. Mattis mauris semper sed amet vitae sed turpis
        id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit. Faucibus
        commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. <a href="#">Mattis mauris semper</a> sed amet
        vitae sed turpis id.
      </p>
      <p>
        Quis semper vulputate aliquam venenatis egestas sagittis quisque orci. Donec commodo sit viverra aliquam
        porttitor ultrices gravida eu. Tincidunt leo, elementum mattis elementum ut nisl, justo, amet, mattis. Nunc
        purus, diam commodo tincidunt turpis. Amet, duis sed elit interdum dignissim.
      </p>
    </template>
  </MoleculeText>
</template>
