<template v-if="!isLoading">
  <div>
    <AtomSectionHeader
      classes="md:px-4 lg:px-8"
      :title="title"
      icon="PlusIcon"
      :action-title="actionTitle"
      is-full-width
      @action="buildForm"
    />
    <div class="mb-4 lg:mb-8">
      <OrganismAlbums :albums="albums" :filters="filters" @update-content="getAlbums" @delete-album="updateAlbumList" />
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    inject: ['sidebar'],
    data() {
      return {
        title: 'Albums',
        actionTitle: 'Create an album',
        error: null,
        albums: null,
        isLoadingNextPage: false,
        page: 1,
        perPage: 12,
        selectedFilters: null,
        form: {
          cta: 'Create album',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'name',
              label: 'Album name',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'event_uid',
              label: 'Event',
              elem: 'select',
              isRequired: null,
              classes: 'sm:col-span-2',
              children: [
                {
                  tag: 'option',
                  text: 'None',
                  value: 'none'
                }
              ],
              instruction: {
                content: "Can't see the event you've attended?",
                url: '/events',
                cta: 'You can add a past event.'
              }
            },
            {
              id: 'date',
              label: 'Date taken',
              elem: 'input',
              type: 'date',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            }
          ]
        },
        filters: {
          fields: [
            {
              id: 'circuit',
              label: 'Circuit',
              elem: 'select',
              isRequired: 'true',
              children: [],
              defaultAll: true,
              classes: 'flex-auto w-full mt-4 lg:mr-4 lg:mt-0'
            },
            {
              id: 'organiser',
              label: 'Organiser',
              elem: 'select',
              isRequired: 'true',
              children: [],
              defaultAll: true,
              classes: 'flex-auto w-full mt-4 lg:mr-4 lg:mt-0'
            },
            {
              id: 'type',
              label: 'Type',
              elem: 'select',
              isRequired: 'true',
              children: [
                {
                  tag: 'option',
                  text: 'Organiser only',
                  value: 'organisers'
                },
                {
                  tag: 'option',
                  text: 'Member only',
                  value: 'members'
                }
              ],
              defaultAll: true,
              classes: 'flex-auto w-full mt-4 lg:mr-4 lg:mt-0'
            }
          ]
        }
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        panelStatus: 'getPanelStatus',
        isLoading: 'getLoadingStatus',
        loggedInUser: 'auth/getLoggedInUser',
        circuits: 'circuits/getCircuits',
        organisers: 'organisers/getOrganisers',
        allEvents: 'events/getEvents',
        today: 'getToday',
        isBottom: 'getIsBottom'
      })
    },
    watch: {
      async panelStatus(res) {
        if (res)
          this.$router.push({
            path: `/album/${res.albumUid}`,
            query: { created: 'true', flickrAlbumUid: res.flickrAlbumUid }
          })
      },
      async isBottom() {
        if (this.isBottom) await this.getNextPage()
      },
      '$route.params': function () {
        if (Object.keys(this.$route.query).length === 0) this.getAlbums()
      }
    },
    mounted() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent() {
        this.$store.dispatch('setLoadingStatus', true)
        this.$store.dispatch('setIsTrackingScrollPos', true)
        await Promise.all([this.getCircuitsWithAlbums(), this.getOrganisersWithAlbums()])
        if (this.$route.query.p) await this.buildForm()
        this.$store.dispatch('setLoadingStatus', false)
      },
      async getCircuitsWithAlbums() {
        const circuits = await this.apiRequest(
          '/circuit/circuits',
          null,
          { albums: true },
          {
            Authorization: this.userJWT
          }
        )
        const circuitFilterField = this.filters.fields.find((field) => field.id === 'circuit')

        circuits.forEach((circuit) => {
          circuitFilterField.children.push({
            tag: 'option',
            text: circuit.name,
            value: circuit.name
          })
        })
      },
      async getOrganisersWithAlbums() {
        const organisers = await this.apiRequest(
          '/organiser/organisers',
          null,
          { albums: true },
          {
            Authorization: this.userJWT
          }
        )
        const organiserFilterField = this.filters.fields.find((field) => field.id === 'organiser')

        organisers.forEach((organiser) => {
          organiserFilterField.children.push({
            tag: 'option',
            text: organiser.name,
            value: organiser.name
          })
        })
      },
      async getAlbums(filters) {
        await Promise.all([
          this.$store.dispatch('circuits/getCircuits'),
          this.$store.dispatch('organisers/getOrganisers')
        ])

        this.selectedFilters = filters

        try {
          const circuits =
            filters && filters.circuit
              ? [this.circuits.find((circuit) => circuit.name === filters.circuit).name]
              : this.circuits && this.circuits.length
              ? this.circuits.map((circuit) => circuit.name)
              : null
          const organisers =
            filters && filters.organiser
              ? [this.organisers.find((organiser) => organiser.name === filters.organiser).name]
              : this.organisers && this.organisers.length
              ? this.organisers.map((organiser) => organiser.name)
              : null

          const albums = await this.apiRequest(
            `/album/albums`,
            null,
            {
              circuits: JSON.stringify(circuits),
              organisers: JSON.stringify(organisers),
              type: filters && filters.type
            },
            {
              Authorization: this.userJWT
            }
          )

          this.albums = albums.albums
          this.page = 2
          if (albums.total > this.albums.length) this.$store.dispatch('setIsTrackingScrollPos', true)
        } catch (e) {
          if (e) console.log(`Error getting albums: ${e}`)
        }
      },
      async getNextPage() {
        const filters = this.selectedFilters
        const cta = event.target

        if (cta && cta.style) cta.style.width = `${cta.offsetWidth}px`
        this.isLoadingNextPage = true

        try {
          const circuits =
            filters && filters.circuit
              ? [this.circuits.find((circuit) => circuit.name === filters.circuit).name]
              : this.circuits
              ? this.circuits.map((circuit) => circuit.name)
              : null
          const organisers =
            filters && filters.organiser
              ? [this.organisers.find((organiser) => organiser.name === filters.organiser).name]
              : this.organisers
              ? this.organisers.map((organiser) => organiser.name)
              : null

          const albums = await this.apiRequest(
            `/album/albums`,
            null,
            {
              circuits: JSON.stringify(circuits),
              organisers: JSON.stringify(organisers),
              official_only: filters.official_only ? true : null,
              page: this.page,
              perPage: this.perPage
            },
            {
              Authorization: this.userJWT
            }
          )

          this.albums = this.albums.concat(albums.albums)
          this.page += 1
          if (this.albums.length >= albums.total) this.$store.dispatch('setIsTrackingScrollPos', false)
        } catch (e) {
          if (e) console.log(`Error getting next page: ${e}`)
        }

        if (cta && cta.style) cta.style.width = null
        this.isLoadingNextPage = false
        this.$store.dispatch('setIsBottom', false)
      },
      async buildForm() {
        // Reset form data
        Object.assign(this.$data.form, this.$options.data().form)

        if (this.loggedInUser.admin) {
          this.form.fields.push({
            id: 'flickrUrl',
            label: 'Flickr Album URL',
            elem: 'input',
            type: 'text',
            isRequired: null,
            classes: 'sm:col-span-2',
            instruction: {
              content: 'If you would like to automatically import photographs from a Flickr album.'
            }
          })
          this.form.fields.push({
            id: 'eventAlbum',
            label: 'This is an official event album',
            elem: 'input',
            type: 'checkbox',
            isRequired: null,
            classes: 'sm:col-span-2 flex justify-end flex-row-reverse'
          })
        }

        await Promise.all([this.getEvents()])

        this.setEvents()
        this.setMaxDate()

        if (this.$route.query.p) {
          let queries = JSON.parse(JSON.stringify(this.$route.query))
          queries.p = undefined

          this.$router.replace({ query: queries })
        }

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          endpoint: '/album',
          errorMessage: "Sorry we couldn't add the album.",
          successMessage: 'Your album has been added.'
        })
      },
      async getEvents() {
        try {
          if (this.loggedInUser.admin) await this.$store.dispatch('events/getEvents', { all: true })
          if (!this.loggedInUser.admin)
            this.events = await this.apiRequest(
              `/user/${this.loggedInUser.id}/events`,
              null,
              { basic: true, start: '2000-01-01', end: this.today },
              {
                Authorization: this.userJWT
              }
            )
        } catch (e) {
          if (e) console.log('Error getEvents')
        }
      },
      async setEvents() {
        const events = this.loggedInUser.admin ? this.allEvents.events.reverse() : this.events
        const eventFormField = this.form.fields.find((field) => field.id === 'event_uid')

        if (!events) return

        const pastEvents = events.filter((event) => {
          return Date.parse(event.start_date) <= Date.now() ? event : null
        })
        const re = /(\d{4})[-. \/](\d\d)[-. \/](\d\d)/

        pastEvents.forEach((event) => {
          const date = event.start_date.slice(0, 10).replace(re, '$3/$2/$1')
          let text = `${date} - ${event.circuit} `

          if (event.slug.includes('evening')) text += `- Evening `
          text += `(${event.organiser})`

          eventFormField.children.push({
            tag: 'option',
            text,
            value: event.uid
          })
        })
      },
      async setMaxDate() {
        const date = this.form.fields.find((field) => field.id === 'date')

        date.max = this.today
      }
    }
  }
</script>
