<template>
  <RouterView />
</template>

<script>
  export default {
    data() {
      return {}
    }
  }
</script>
