<template v-if="!isLoading">
  <div class="mb-4 lg:mb-8">
    <MoleculeCover :cover-photo-url="coverPhotoUrl" @show-panel="buildEditForm()" />

    <AtomPageTitle v-if="isCircuit" :title="circuit.name" class="border-b border-gray" />

    <div class="mb-4 divide-gray divide-y space-y-4">
      <section v-if="hasEvents" :class="[!hideEventsViewMore ? 'space-y-4 mb-4 lg:mb-8' : null]">
        <AtomSectionHeader
          classes="md:px-4 lg:px-8"
          title="Events"
          icon="PlusIcon"
          :action-title="loggedInUser.admin ? 'Add an event' : 'Add a past event'"
          @action="buildForm()"
        />
        <OrganismEvents :events="events" />
        <button
          v-if="!hideEventsViewMore && !isLoadingEvents"
          type="submit"
          :class="[
            'flex mx-auto mt-4 items-center justify-center border border-gray w-full lg:w-auto py-2 px-4 rounded-sm transition-colors ease-in-out duration-300 lg:hover:bg-orange-500 lg:hover:text-white lg:hover:border-orange-500 focus:outline-none focus:ring-orange-500 focus:border-orange-500',
            !isLoadingNextEventsPage ? 'lg:hover:bg-orange-300' : 'bg-orange-500 cursor-default'
          ]"
          @click.prevent="getEvents()"
        >
          <span v-if="!isLoadingNextEventsPage" class="pointer-events-none"> Show more </span>
          <AtomLoader v-else></AtomLoader>
        </button>
      </section>

      <section v-if="hasAlbums" class="pb-4 lg:pb-8">
        <AtomSectionHeader classes="md:px-4 lg:px-8" title="Albums" heading-tag="h2" @action="buildForm" />
        <OrganismAlbums on-circuit centered :albums="albums" />
        <button
          v-if="!hideAlbumsViewMore && !isLoadingAlbums"
          type="submit"
          :class="[
            'flex mx-auto mt-4 items-center justify-center border border-gray w-full lg:w-auto py-2 px-4 rounded-sm transition-colors ease-in-out duration-300 lg:hover:bg-orange-500 lg:hover:text-white lg:hover:border-orange-500 focus:outline-none focus:ring-orange-500 focus:border-orange-500',
            !isLoadingNextAlbumsPage ? 'lg:hover:bg-orange-300' : 'bg-orange-500 cursor-default'
          ]"
          @click.prevent="getAlbums()"
        >
          <span v-if="!isLoadingNextAlbumsPage" class="pointer-events-none"> Show more </span>
          <AtomLoader v-else></AtomLoader>
        </button>
      </section>

      <section v-if="hasVideos" class="pb-4 lg:pb-8">
        <AtomSectionHeader classes="md:px-4 lg:px-8" title="Videos" heading-tag="h2" />
        <OrganismVideos :videos="videos" on-circuit centered @update-videos="updateVideoList" />
        <button
          v-if="!hideVideosViewMore && !isLoadingVideos"
          type="submit"
          :class="[
            'flex mx-auto mt-4 items-center justify-center border border-gray w-full lg:w-auto py-2 px-4 rounded-sm transition-colors ease-in-out duration-300 lg:hover:bg-orange-500 lg:hover:text-white lg:hover:border-orange-500 focus:outline-none focus:ring-orange-500 focus:border-orange-500',
            !isLoadingNextVideosPage ? 'lg:hover:bg-orange-300' : 'bg-orange-500 cursor-default'
          ]"
          @click.prevent="getVideos()"
        >
          <span v-if="!isLoadingNextVideosPage" class="pointer-events-none"> Show more </span>
          <AtomLoader v-else></AtomLoader>
        </button>
      </section>

      <section v-if="hasLayouts" class="pb-4 lg:pb-8">
        <AtomSectionHeader classes="md:px-4 lg:px-8" title="Lap times" />
        <div class="px-4 lg:px-8">
          <template v-for="(layout, idx) in circuit.layouts" :key="layout.uid">
            <MoleculeCircuitLayout
              v-if="layout.laptimes"
              :circuit-name="layout.name"
              :laptimes="layout.laptimes"
              :is-editable="false"
              :class="[
                'flex flex-wrap justify-between flex-col mt-4 lg:mt-8 pb-4 lg:pb-8',
                idx > 0 ? 'mt-8' : null,
                idx === circuit.layouts.length - 1 ? null : 'border-b border-gray'
              ]"
            />
          </template>
        </div>
      </section>

      <section v-if="circuit" class="pb-4 lg:pb-8">
        <AtomSectionHeader classes="md:px-4 lg:px-8" title="Comments" heading-tag="h2" />
        <OrganismComments :comments="comments" :content-uid="circuit.uid" @update-comments="updateCommentList" />
      </section>

      <section v-if="hasOrganisers">
        <AtomSectionHeader classes="md:px-4 lg:px-8" title="Organisers" heading-tag="h2" />
        <OrganismOrganisers :organisers="circuit.organisers" />
      </section>

      <!-- <section v-if="isCircuit">
      <AtomSectionHeader classes="md:px-4 lg:px-8" title="Contact Details" heading-tag="h2" />
      <AtomContact :details="circuit" />
    </section> -->
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    inject: ['sidebar'],
    props: {},
    data() {
      return {
        isImageLoaded: false,
        circuit: null,
        events: [],
        albums: [],
        videos: [],
        comments: null,
        isSaving: false,
        eventsPage: 1,
        eventsPerPage: 10,
        hideEventsViewMore: false,
        isLoadingNextEventsPage: null,
        isLoadingEvents: true,
        albumsPage: 1,
        albumsPerPage: 6,
        hideAlbumsViewMore: false,
        isLoadingNextAlbumsPage: null,
        isLoadingAlbums: true,
        videosPage: 1,
        videosPerPage: 6,
        hideVideosViewMore: false,
        isLoadingNextVideosPage: null,
        isLoadingVideos: true,
        editActionTitle: 'Update circuit',
        actionTitle: 'Add event',
        form: {
          cta: 'Add event',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'circuit_uid',
              label: 'Circuit',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'layout_uid',
              label: 'Layout',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'organiser_uid',
              label: 'Organiser',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'format_uid',
              label: 'Format',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'start_date',
              label: 'Date',
              elem: 'input',
              type: 'date',
              min: '2000-01-01',
              max: `${new Date().getFullYear()}-12-31`,
              isRequired: 'true'
            },
            {
              id: 'url',
              label: 'Event URL',
              elem: 'input',
              type: 'text',
              isRequired: null
            }
          ]
        }
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        panelStatus: 'getPanelStatus',
        isLoading: 'getLoadingStatus',
        loggedInUser: 'auth/getLoggedInUser',
        circuits: 'circuits/getCircuits',
        organisers: 'organisers/getOrganisers',
        formats: 'formats/getFormats',
        today: 'getToday'
      }),
      isAdmin() {
        return this.loggedInUser && this.loggedInUser.admin
      },
      isCircuit() {
        return !!this.circuit
      },
      hasAlbums() {
        return !!this.albums && !!this.albums.length
      },
      hasVideos() {
        return !!this.videos && !!this.videos.length
      },
      hasOrganisers() {
        return this.circuit && this.circuit.organisers
      },
      hasEvents() {
        return !!this.events
      },
      hasLayouts() {
        return this.circuit && this.circuit.layouts
      },
      coverPhotoUrl() {
        return this.circuit && this.circuit.hero ? `${process.env.VUE_APP_IMGIX}/${this.circuit.hero.path}` : null
      },
      hasLeaderboards() {
        return !!this.circuit && !!this.circuit.layouts
      },
      editForm() {
        return {
          cta: 'Update circuit',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          initial: {
            name: this.circuit.name,
            address_1: this.circuit.address_1,
            address_2: this.circuit.address_2,
            address_3: this.circuit.address_3,
            town_city: this.circuit.town_city,
            post_code: this.circuit.post_code,
            country: this.circuit.country,
            email: this.circuit.email,
            telephone: this.circuit.telephone
          },
          fields: [
            {
              id: 'name',
              label: 'Name',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'address_1',
              label: 'Address Line 1',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'address_2',
              label: 'Address Line 2',
              elem: 'input',
              type: 'text',
              isRequired: null,
              classes: 'sm:col-span-2'
            },
            {
              id: 'address_3',
              label: 'Address Line 3',
              elem: 'input',
              type: 'text',
              isRequired: null,
              classes: 'sm:col-span-2'
            },
            {
              id: 'town_city',
              label: 'Town',
              elem: 'input',
              type: 'text',
              isRequired: null
            },
            {
              id: 'post_code',
              label: 'Postcode',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'country',
              label: 'Country',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'email',
              label: 'Email Address',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'telephone',
              label: 'Phone Number',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            }
          ]
        }
      }
    },
    watch: {
      async panelStatus(res) {
        if (res && res.type !== 'event') {
          this.$router.replace({
            path: `/circuit/${res.slug}`
          })
          await this.getCircuitBySlug(res.slug)
        }

        if (res && res.type === 'event' && res.slug) {
          this.$router.push({
            path: `/event/${res.slug}`
          })
        }
      }
    },
    mounted() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent(slug) {
        this.$store.dispatch('setLoadingStatus', true)

        await this.getCircuitBySlug(slug)
        await Promise.all([
          this.getHero(),
          this.getEvents(),
          this.getAlbums(),
          this.getVideos(),
          this.getLayouts(),
          this.getOrganisers(),
          this.getComments()
        ])
        // if (this.$route.query.u) this.showVideo(this.$route.query.u)
        this.$store.dispatch('setLoadingStatus', false)
      },
      async getCircuitBySlug(slug = this.$route.params.slug) {
        try {
          this.circuit = await this.apiRequest(`/circuit/${slug}`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          throw new Error('Circuit not found')
          if (e) console.log(`Error getting circuit: ${e.status} ${e.data}`)
        }
      },
      async getHero() {
        try {
          this.circuit.hero = await this.apiRequest(`/circuit/${this.circuit.uid}/photographs/popular`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log(`Error getting hero: ${e.status} ${e.data}`)
        }
      },
      async getEvents() {
        const cta = event.target

        if (cta && cta.style) cta.style.width = `${cta.offsetWidth}px`
        this.isLoadingNextEventsPage = true

        try {
          const events = await this.apiRequest(
            `/circuit/${this.circuit.uid}/events`,
            null,
            {
              start: this.today,
              end: `${new Date().getFullYear()}-12-31`,
              page: this.eventsPage,
              perPage: this.eventsPerPage
            },
            { Authorization: this.userJWT }
          )

          this.events = this.events.concat(events.events)
          if (this.events.length >= parseInt(events.total)) this.hideEventsViewMore = true
          this.eventsPage += 1
          this.isLoadingEvents = false
        } catch (e) {
          if (e) console.log(`Error getting events: ${e}`)
        }

        if (cta && cta.style) cta.style.width = null
        this.isLoadingNextEventsPage = false
      },
      async getAlbums() {
        const cta = event.target

        if (cta && cta.style) cta.style.width = `${cta.offsetWidth}px`
        this.isLoadingNextAlbumsPage = true

        try {
          const albums = await this.apiRequest(
            `/circuit/${this.circuit.uid}/albums`,
            null,
            {
              page: this.albumsPage,
              perPage: this.albumsPerPage
            },
            { Authorization: this.userJWT }
          )

          this.albums = this.albums.concat(albums.albums)
          if (this.albums.length >= parseInt(albums.total)) this.hideAlbumsViewMore = true
          this.albumsPage += 1
          this.isLoadingAlbums = false
        } catch (e) {
          if (e) console.log(`Error getting albums: ${e}`)
        }

        if (cta && cta.style) cta.style.width = null
        this.isLoadingNextAlbumsPage = false
      },
      async getVideos() {
        const cta = event.target

        if (cta && cta.style) cta.style.width = `${cta.offsetWidth}px`
        this.isLoadingNextVideosPage = true

        try {
          const videos = await this.apiRequest(
            `/circuit/${this.circuit.uid}/videos`,
            null,
            {
              page: this.videosPage,
              perPage: this.videosPerPage
            },
            { Authorization: this.userJWT }
          )

          this.videos = this.videos.concat(videos.videos)
          if (this.videos.length >= parseInt(videos.total)) this.hideVideosViewMore = true
          this.videosPage += 1
          this.isLoadingVideos = false
        } catch (e) {
          if (e) console.log(`Error getting videos: ${e}`)
        }

        if (cta && cta.style) cta.style.width = null
        this.isLoadingNextVideosPage = false
      },
      async getLayouts() {
        try {
          this.circuit.layouts = await this.apiRequest(`/circuit/${this.circuit.uid}/layouts`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log(`Error getting layouts: ${e.status} ${e.data}`)
        }

        if (!!this.circuit.layouts.length) {
          try {
            await Promise.all(
              this.circuit.layouts.map(async (layout) => {
                if (!!parseInt(layout.count)) {
                  const laps = await this.apiRequest(`/laptime/laptimes/${layout.uid}`, null, null, {
                    Authorization: this.userJWT
                  })

                  if (!!laps.length) {
                    laps.sort((a, b) => a.laptime - b.laptime)
                    layout.laptimes = laps
                  }
                }
              })
            )
          } catch (e) {
            console.log('Error getting laptimes', e)
          }
        }
      },
      async getOrganisers() {
        try {
          this.circuit.organisers = await this.apiRequest(`/circuit/${this.circuit.uid}/organisers`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log(`Error getting circuit organisers: ${e.status} ${e.data}`)
        }
      },
      async getComments() {
        const headers = {}

        if (this.userJWT) headers.Authorization = this.userJWT

        try {
          this.comments = await this.apiRequest('/comment/comments', null, { u: this.circuit.uid }, headers)

          if (this.comments === '') this.comments = []
        } catch (e) {
          if (e) console.log(`Error getting comments: ${e.status} ${e.data}`)
        }
      },
      async buildForm() {
        // Reset form data
        Object.assign(this.$data.form, this.$options.data().form)

        await Promise.all([
          this.$store.dispatch('circuits/getCircuits'),
          this.$store.dispatch('organisers/getOrganisers'),
          this.$store.dispatch('formats/getFormats')
        ])

        this.setCircuits()
        this.setCircuitLayouts()
        this.setOrganisers()
        this.setFormats()
        this.setMaxDate()

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          endpoint: '/event',
          errorMessage: "Sorry we couldn't add the event."
        })
      },
      async buildEditForm() {
        this.$store.dispatch('setPanel', {
          title: this.editActionTitle,
          form: this.editForm,
          method: 'PUT',
          endpoint: `/circuit/${this.circuit.uid}`,
          errorMessage: "Sorry we couldn't update the circuit.",
          successMessage: 'The circuit has been updated.'
        })
      },
      async setCircuits() {
        const circuitFormField = this.form.fields.find((field) => field.id === 'circuit_uid')

        circuitFormField.children.push({
          tag: 'option',
          text: this.circuit.name,
          value: this.circuit.uid
        })

        circuitFormField.selected = this.circuit.uid
      },
      async setCircuitLayouts() {
        const layoutFormField = this.form.fields.find((field) => field.id === 'layout_uid')
        const layouts = await this.apiRequest(`/circuit/${this.circuit.uid}/layouts`, null, null, {
          Authorization: this.userJWT
        })

        layouts.forEach((layout) => {
          layoutFormField.children.push({
            tag: 'option',
            text: layout.name,
            value: layout.layout_uid
          })
        })
      },
      async setOrganisers() {
        const organiserFormField = this.form.fields.find((field) => field.id === 'organiser_uid')
        this.organisers.forEach((organiser) => {
          organiserFormField.children.push({
            tag: 'option',
            text: organiser.name,
            value: organiser.uid
          })
        })
      },
      async setFormats() {
        const formatFormField = this.form.fields.find((field) => field.id === 'format_uid')

        this.formats.forEach((format) => {
          formatFormField.children.push({
            tag: 'option',
            text: format.name,
            value: format.uid
          })
        })
      },
      async setMaxDate() {
        const start_date = this.form.fields.find((field) => field.id === 'start_date')

        start_date.max = this.loggedInUser.admin ? '2030-12-31' : this.today
      },
      imageLoaded() {
        this.isImageLoaded = true
      },
      async updateVideoList(videoUid) {
        this.videos = this.videos.filter((video) => {
          return video.uid !== videoUid
        })
      },
      async updateCommentList(commentUid) {
        if (commentUid) {
          this.comments = this.comments.filter((comment) => {
            return comment.uid !== commentUid
          })
        } else {
          this.getComments()
        }
      },
      async showVideo(videoUid) {
        let video = this.videos ? this.videos.find((video) => video.uid === videoUid) : null

        if (video)
          this.$store.dispatch('setOverlay', {
            youtube_id: video.youtube_id,
            uid: video.uid,
            video_uid: video.uid,
            url: video.uid,
            owner_uid: video.owner_uid,
            liked: video.liked ? video.liked : null,
            title: `${video.circuit_name} - ${video.layout_name}`,
            content: video.description,
            meta: {
              username: video.username,
              first_name: video.first_name,
              last_name: video.last_name,
              date: video.event_date,
              url: video.event_slug,
              lap_time: video.lap_time
            }
          })
      }
    }
  }
</script>
