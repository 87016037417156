<template>
  <div class="mb-32 min-h-screen lg:mb-0">
    <section v-if="!isLoading">
      <OrganismAlbums :albums="organisersAlbums" />
    </section>
    <h2 v-else class="flex mt-4 text-black text-2xl font-bold tracking-tight leading-tight md:px-4 lg:px-8">
      Loading…
    </h2>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    inject: ['sidebar'],
    props: {
      albums: Array
    },
    data() {
      return {
        organisersAlbums: null,
        isLoading: null
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        panelStatus: 'getPanelStatus',
        loggedInUser: 'auth/getLoggedInUser',
        allEvents: 'events/getEvents'
      })
    },
    watch: {
      async panelStatus(res) {
        if (res)
          this.$router.push({
            path: `/album/${res.albumUid}`,
            query: { created: 'true', flickrAlbumUid: res.flickrAlbumUid }
          })
      }
    },
    mounted() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent() {
        this.isLoading = true
        this.organisersAlbums = this.albums.filter((album) => album.organiser_album)
        this.isLoading = false
      }
    }
  }
</script>
