<template>
  <div>
    <AtomSectionHeader classes="md:px-4 lg:px-8" :title="header.title" />

    <main class="my-4 px-4 min-h-screen lg:my-8 lg:px-8">
      <div v-if="loggedInUser" class="mx-auto max-w-screen-2xl">
        <Form
          :validation-schema="formSchema.validation"
          :initial-values="formSchema.values"
          class="divide-gray divide-y space-y-8"
          @submit="submitForm"
        >
          <div class="divide-gray divide-y space-y-8 sm:space-y-5">
            <div>
              <div>
                <h3 class="text-black text-lg font-medium leading-6">Account</h3>
                <p class="mt-1 max-w-2xl text-black text-sm">Update your profile name or password.</p>
              </div>

              <div class="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                <div class="sm:grid sm:gap-4 sm:grid-cols-3 sm:items-start sm:pt-5 sm:border-t sm:border-gray">
                  <label for="username" class="block text-black text-sm font-medium sm:mt-px sm:pt-2">
                    Profile name *
                  </label>
                  <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <div class="flex max-w-lg rounded-sm">
                      <span
                        class="
                          inline-flex
                          items-center
                          px-3
                          text-black
                          bg-gray
                          border border-r-0 border-gray
                          rounded-l-sm
                          sm:text-sm
                        "
                      >
                        tracklimit.co.uk/
                      </span>
                      <Field
                        id="username"
                        name="username"
                        as="input"
                        type="text"
                        class="
                          block
                          flex-1
                          w-full
                          min-w-0
                          border-gray
                          focus:border-orange-500
                          rounded-none rounded-r-sm
                          focus:ring-orange-500
                          sm:text-sm
                        "
                      />
                    </div>
                    <p class="mt-2 text-black text-sm">You can choose your own unique profile name (alphanumeric)</p>
                    <ErrorMessage name="username" />
                  </div>
                </div>

                <div class="sm:grid sm:gap-4 sm:grid-cols-3 sm:items-start sm:pt-5 sm:border-t sm:border-gray">
                  <label for="id" class="block text-black text-sm font-medium sm:mt-px"> Password </label>
                  <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <p class="text-black text-sm">
                      <RouterLink
                        to="/account/password"
                        class="
                          lg:hover:border-orange-500
                          lg:hover:text-orange-500
                          text-black
                          border-b border-black
                          transition-colors
                          duration-150
                          ease-in-out
                        "
                        >Change your password</RouterLink
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="pt-8 lg:pt-24">
              <div>
                <h3 class="text-black text-lg font-medium leading-6">Socials</h3>
                <p class="mt-1 max-w-2xl text-black text-sm">
                  Add your other social accounts so members can follow you.
                </p>
              </div>

              <div class="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                <div v-for="field in formSchemaSocial.fields" :key="field.id" :class="field.classes">
                  <div
                    class="sm:grid sm:gap-4 sm:grid-cols-3 sm:items-start"
                    :class="[!field.combinedFields ? 'sm:border-t sm:border-gray sm:pt-5' : '']"
                  >
                    <label for="field.id" class="block text-black text-sm font-medium sm:mt-px sm:pt-2">
                      {{ field.label }} <span v-if="field.isRequired">*</span>
                    </label>
                    <div class="flex max-w-lg rounded-sm">
                      <span
                        class="
                          inline-flex
                          items-center
                          px-3
                          text-black
                          bg-gray
                          border border-r-0 border-gray
                          rounded-l-sm
                          sm:text-sm
                        "
                      >
                        @
                      </span>
                      <Field
                        v-if="field.type"
                        :id="field.id"
                        :name="field.id"
                        :as="field.elem"
                        :type="field.type"
                        :rules="field.isRequired"
                        class="
                          block
                          w-full
                          max-w-lg
                          border-gray
                          focus:border-orange-500
                          rounded-none rounded-r-sm
                          focus:ring-orange-500
                          sm:text-sm
                        "
                        :class="[field.wide ? 'max-w-lg' : 'sm:max-w-xs']"
                        v-bind="attrs"
                      >
                        <template v-if="field.children && field.children.length">
                          <component
                            :is="tag"
                            v-for="({ tag, text, ...childAttrs }, idx) in field.children"
                            :key="idx"
                            v-bind="childAttrs"
                          >
                            {{ text }}
                          </component>
                        </template>
                      </Field>
                      <Field
                        v-else
                        :id="field.id"
                        :ref="field.id"
                        v-model="select[field.id]"
                        :name="field.id"
                        :as="field.elem"
                        :rules="field.isRequired"
                        :multiple="field.multiple"
                        :size="field.size"
                        :disabled="field.disabled"
                        class="
                          block
                          w-full
                          max-w-lg
                          border-gray
                          focus:border-orange-500
                          rounded-sm
                          focus:ring-orange-500
                          sm:text-sm
                        "
                      >
                        <option v-if="!field.multiple" disabled selected value="">Please select…</option>

                        <template v-if="field.children && field.children.length">
                          <component
                            :is="tag"
                            v-for="{ tag, text, value, disabled } in field.children"
                            :key="value"
                            :value="value"
                            :disabled="disabled"
                          >
                            {{ text }}
                          </component>
                        </template>
                      </Field>
                      <ErrorMessage :name="field.id" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pt-8 lg:pt-24">
              <div>
                <h3 class="text-black text-lg font-medium leading-6">Profile</h3>
                <p class="mt-1 max-w-2xl text-black text-sm">
                  Some of this information will be displayed on your profile.
                </p>
              </div>

              <div class="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                <div class="sm:grid sm:gap-4 sm:grid-cols-3 sm:items-start sm:pt-5 sm:border-t sm:border-gray">
                  <label for="cover_photo" class="block text-black text-sm font-medium sm:mt-px sm:pt-2">
                    Cover photo
                  </label>
                  <img
                    v-if="coverPhoto"
                    class="mt-2 sm:col-span-2 sm:mt-0"
                    :src="coverPhotoUrl"
                    :alt="loggedInUser.username"
                  />
                  <div class="mt-2 sm:col-span-2 sm:col-start-2 sm:mt-0">
                    <AtomFilePond
                      reference="coverPhotoUpload"
                      name="coverPhoto"
                      :title="coverPhotoTitle"
                      :multiple="false"
                      :folder="uploadFolder"
                      upload-type="cover"
                      @upload-complete="refreshForm"
                      @uploading="isSaving = true"
                    />
                  </div>
                </div>
              </div>

              <div class="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                <div v-for="field in formSchema.fields" :key="field.id" :class="field.classes">
                  <div
                    class="sm:grid sm:gap-4 sm:grid-cols-3 sm:items-start"
                    :class="[!field.combinedFields ? 'sm:border-t sm:border-gray sm:pt-5' : '']"
                  >
                    <label for="field.id" class="block text-black text-sm font-medium sm:mt-px sm:pt-2">
                      {{ field.label }} <span v-if="field.isRequired">*</span>
                    </label>
                    <div class="mt-1 sm:mt-0" :class="[field.wide ? 'sm:col-span-2' : '']">
                      <Field
                        v-if="field.type"
                        :id="field.id"
                        :name="field.id"
                        :as="field.elem"
                        :type="field.type"
                        :rules="field.isRequired"
                        class="
                          block
                          w-full
                          max-w-lg
                          border-gray
                          focus:border-orange-500
                          rounded-sm
                          focus:ring-orange-500
                          sm:text-sm
                        "
                        :class="[field.wide ? 'max-w-lg' : 'sm:max-w-xs']"
                        v-bind="attrs"
                      >
                        <template v-if="field.children && field.children.length">
                          <component
                            :is="tag"
                            v-for="({ tag, text, ...childAttrs }, idx) in field.children"
                            :key="idx"
                            v-bind="childAttrs"
                          >
                            {{ text }}
                          </component>
                        </template>
                      </Field>
                      <Field
                        v-else
                        :id="field.id"
                        :ref="field.id"
                        v-model="select[field.id]"
                        :name="field.id"
                        :as="field.elem"
                        :rules="field.isRequired"
                        :multiple="field.multiple"
                        :size="field.size"
                        :disabled="field.disabled"
                        class="
                          block
                          w-full
                          max-w-lg
                          border-gray
                          focus:border-orange-500
                          rounded-sm
                          focus:ring-orange-500
                          sm:text-sm
                        "
                      >
                        <option v-if="!field.multiple" disabled selected value="">Please select…</option>

                        <template v-if="field.children && field.children.length">
                          <component
                            :is="tag"
                            v-for="{ tag, text, value, disabled } in field.children"
                            :key="value"
                            :value="value"
                            :disabled="disabled"
                          >
                            {{ text }}
                          </component>
                        </template>
                      </Field>
                      <ErrorMessage :name="field.id" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pt-8 lg:pt-24">
              <div>
                <h3 class="text-black text-lg font-medium leading-6">Emails</h3>
                <p class="mt-1 max-w-2xl text-black text-sm">You can opt in or out of email communications.</p>
              </div>

              <div class="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                <div>
                  <div class="sm:grid sm:gap-4 sm:grid-cols-3 sm:items-start sm:pt-5 sm:border-t sm:border-gray">
                    <div class="flex flex-row-reverse justify-end sm:col-span-2 lg:items-center">
                      <label for="opt_in_marketing" class="block ml-2 text-black text-sm font-medium cursor-pointer">
                        Newsletters with updates about new or changed features
                      </label>
                      <div>
                        <input
                          id="opt_in_marketing"
                          ref="opt_in_marketing"
                          type="checkbox"
                          class="
                            form-input
                            block
                            mt-0.5
                            w-6
                            h-6
                            text-orange-500
                            checked:bg-orange-500
                            border-black
                            focus:border-orange-500
                            rounded-sm
                            focus:outline-none
                            shadow-none
                            transition
                            duration-150
                            ease-in-out
                            focus:ring-0
                            lg:mt-0
                          "
                          name="opt_in_marketing"
                          :checked="loggedInUser.opt_in_marketing"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="sm:grid sm:gap-4 sm:grid-cols-3 sm:items-start sm:pt-5 sm:border-t sm:border-gray">
                    <div class="flex flex-row-reverse justify-end sm:col-span-2 lg:items-center">
                      <label for="opt_in_help" class="block ml-2 text-black text-sm font-medium cursor-pointer">
                        Helpful tips about what you can do on and reminders about your upcoming events
                      </label>
                      <div>
                        <input
                          id="opt_in_help"
                          ref="opt_in_help"
                          type="checkbox"
                          class="
                            form-input
                            block
                            mt-0.5
                            w-6
                            h-6
                            text-orange-500
                            checked:bg-orange-500
                            border-black
                            focus:border-orange-500
                            rounded-sm
                            focus:outline-none
                            shadow-none
                            transition
                            duration-150
                            ease-in-out
                            focus:ring-0
                            lg:mt-0
                          "
                          name="opt_in_help"
                          :checked="loggedInUser.opt_in_help"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="sm:grid sm:gap-4 sm:grid-cols-3 sm:items-start sm:pt-5 sm:border-t sm:border-gray">
                    <div class="flex flex-row-reverse justify-end sm:col-span-2 lg:items-center">
                      <label
                        for="opt_in_notifications"
                        class="block ml-2 text-black text-sm font-medium cursor-pointer"
                      >
                        Notifications when someone comments/replies to you or adds new content to an event you attended
                      </label>
                      <div>
                        <input
                          id="opt_in_notifications"
                          ref="opt_in_notifications"
                          type="checkbox"
                          class="
                            form-input
                            block
                            mt-0.5
                            w-6
                            h-6
                            text-orange-500
                            checked:bg-orange-500
                            border-black
                            focus:border-orange-500
                            rounded-sm
                            focus:outline-none
                            shadow-none
                            transition
                            duration-150
                            ease-in-out
                            focus:ring-0
                            lg:mt-0
                          "
                          name="opt_in_notifications"
                          :checked="loggedInUser.opt_in_notifications"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-4">
            <div class="sm:flex sm:justify-end">
              <button
                ref="submitBtn"
                :disabled="isSaving"
                type="submit"
                :class="[
                  'w-full sm:w-44 inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-sm text-white bg-orange-500 focus:outline-none focus:border-orange-300 active:bg-orange-300 transition ease-in-out duration-150',
                  !isSaving ? 'hover:bg-orange-300 ' : 'bg-orange-300 cursor-default'
                ]"
              >
                <span v-if="!isSaving">Update details</span>
                <AtomLoader v-else></AtomLoader>
              </button>
            </div>
          </div>
        </Form>
      </div>
    </main>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'
  import { Field, Form, ErrorMessage } from 'vee-validate'
  import * as yup from 'yup'
  import AtomFilePond from '@/components/atoms/FilePond.vue'

  export default {
    components: {
      AtomFilePond,
      ErrorMessage,
      Field,
      Form
    },
    inject: ['sidebar'],
    data() {
      return {
        attrs: null,
        isLoading: false,
        isSaving: false,
        coverPhoto: null,
        members: null,
        profilePhoto: null,
        refresh: false,
        header: {
          title: 'Settings',
          content: 'Manage your account and profile settings.'
        },
        select: {},
        input: {}
      }
    },
    computed: {
      ...mapGetters({
        loggedInUser: 'auth/getLoggedInUser',
        userJWT: 'getJWT'
      }),
      formSchema() {
        const user = this.loggedInUser

        return {
          fields: [
            {
              id: 'first_name',
              label: 'First name',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'last_name',
              label: 'Last name',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'town_city',
              label: 'City / Town',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'country',
              label: 'Country',
              elem: 'select',
              isRequired: 'true',
              children: [
                {
                  tag: 'option',
                  text: 'Germany',
                  value: 'Germany'
                },
                {
                  tag: 'option',
                  text: 'Ireland',
                  value: 'Ireland'
                },
                {
                  tag: 'option',
                  text: 'United Kingdom',
                  value: 'United Kingdom'
                },
                {
                  tag: 'option',
                  text: 'United States of America',
                  value: 'United States of America'
                }
              ]
            }
          ],
          values: {
            username: user.username,
            first_name: user.first_name,
            last_name: user.last_name,
            town_city: user.town_city,
            country: user.country,
            facebook: user.facebook,
            instagram: user.instagram
          },
          validation: yup.object().shape({
            username: yup
              .string()
              .required('Profile name is required')
              .matches(/^[a-zA-Z0-9-_]+$/, {
                message: 'Alphanumeric characters only',
                excludeEmptyString: true
              })
              .test('checkUsernameUnique', 'This username is taken, please try another', async (value) => {
                if (value === user.username) return true

                return !!!this.members.find((member) => member.username.toLowerCase() === value.toLowerCase())
              })
              .label('Profile URL'),
            first_name: yup.string().required('First name is required').label('First Name'),
            last_name: yup.string().required('Last name is required').label('Last Name'),
            town_city: yup.string().required('City/Town is required').label('City / Town'),
            country: yup.string().required('Country is required').label('Country')
          })
        }
      },
      formSchemaSocial() {
        const user = this.loggedInUser

        return {
          fields: [
            {
              id: 'facebook',
              label: 'Facebook',
              elem: 'input',
              type: 'text'
            },
            {
              id: 'instagram',
              label: 'Instagram',
              elem: 'input',
              type: 'text'
            }
          ],
          values: {
            facebook: user.facebook,
            instagram: user.instagram
          },
          validation: yup.object().shape({
            facebook: yup.string().label('Facebook'),
            instagram: yup.string().label('Instagram')
          })
        }
      },
      uploadFolder() {
        return this.loggedInUser ? `users/${this.loggedInUser.id}` : null
      },
      coverPhotoUrl() {
        return this.coverPhoto ? `${process.env.VUE_APP_IMGIX}/${this.coverPhoto}?w=810&ar=2:1&fit=crop` : null
      },
      coverPhotoTitle() {
        return this.coverPhoto ? 'Change cover photo…' : 'Set cover photo'
      },
      profilePhotoUrl() {
        return this.profilePhoto ? `${process.env.VUE_APP_IMGIX}/${this.profilePhoto}?w=76&h=76&fit=crop` : null
      },
      profilePhotoTitle() {
        return this.profilePhoto ? 'Change profile photo…' : 'Set profile photo'
      }
    },
    watch: {
      refresh: {
        handler() {
          this.$refs.submitBtn.click()
        }
      }
    },
    mounted() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent() {
        await Promise.all([this.getAccountPhotographs(), this.getMembers()])
      },
      async getMembers() {
        try {
          const members = await this.apiRequest(
            `/user/users`,
            null,
            { all: true },
            {
              Authorization: this.userJWT
            }
          )

          this.members = members.members
        } catch (e) {
          if (e) console.log(`Error getting users: ${e.status} ${e.data}`)
        }
      },
      async submitForm(values, { resetForm }) {
        this.isSaving = true

        values.opt_in_marketing = this.$refs.opt_in_marketing.checked
        values.opt_in_help = this.$refs.opt_in_help.checked
        values.opt_in_notifications = this.$refs.opt_in_notifications.checked

        this.$store.dispatch('auth/updateAccount', values)

        this.refresh = false
        this.isSaving = false
      },
      async getAccountPhotographs() {
        if (this.loggedInUser.cover_photograph_uid) {
          try {
            const coverPhoto = await this.apiRequest(`/photograph/${this.loggedInUser.cover_photograph_uid}`)
            if (coverPhoto) this.coverPhoto = `users/${this.loggedInUser.id}/${coverPhoto.filename}`
          } catch (e) {
            console.log('error getting cover photograph')
          }
        }

        // if (this.loggedInUser.profile_photograph_uid) {
        //   try {
        //     const profilePhoto = await this.apiRequest(
        //       `/photograph/${this.loggedInUser.profile_photograph_uid}`
        //     )
        //     if (profilePhoto)
        //       this.profilePhoto = `users/${this.loggedInUser.id}/${profilePhoto.filename}`
        //   } catch (e) {
        //     console.log('error getting profile photograph')
        //   }
        // }
      },
      async refreshForm(values) {
        this.loggedInUser.cover_photograph_uid = values.uid
        this.getAccountPhotographs()
        this.refresh = true
        this.isSaving = false
      }
    }
  }
</script>
