<template v-if="!isLoading">
  <div>
    <AtomSectionHeader
      classes="md:px-4 lg:px-8"
      :title="title"
      icon="PlusIcon"
      :action-title="actionTitle"
      :admin-action="true"
      @action="buildForm"
    />

    <div class="mb-4 lg:mb-8">
      <OrganismCircuits :circuits="circuits" @update-circuits="updateCircuitList" />
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    inject: ['sidebar'],
    data() {
      return {
        title: 'Circuits',
        actionTitle: 'Add a circuit',
        content: 'You can view all of the circuits below.',
        error: null,
        form: {
          cta: 'Add circuit',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'name',
              label: 'Name',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'address_1',
              label: 'Address Line 1',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'address_2',
              label: 'Address Line 2',
              elem: 'input',
              type: 'text',
              isRequired: null,
              classes: 'sm:col-span-2'
            },
            {
              id: 'address_3',
              label: 'Address Line 3',
              elem: 'input',
              type: 'text',
              isRequired: null,
              classes: 'sm:col-span-2'
            },
            {
              id: 'town',
              label: 'Town',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'post_code',
              label: 'Postcode',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'country',
              label: 'Country',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'email',
              label: 'Email Address',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'telephone',
              label: 'Phone Number',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            }
          ]
        },
        isSaving: false,
        jwt: null
      }
    },
    computed: {
      ...mapGetters({
        panelStatus: 'getPanelStatus',
        isLoading: 'getLoadingStatus',
        loggedInUser: 'auth/getLoggedInUser',
        circuits: 'circuits/getCircuits'
      })
    },
    watch: {
      async panelStatus(res) {
        if (res)
          this.$router.push({
            path: `/circuit/${res.circuitUid}`,
            query: { created: 'true' }
          })
      }
    },
    mounted() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent() {
        this.$store.dispatch('setLoadingStatus', true)
        this.$store.dispatch('circuits/getCircuits')
        this.$store.dispatch('setLoadingStatus', false)
      },
      async buildForm() {
        // Reset form data
        Object.assign(this.$data.form, this.$options.data().form)

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          endpoint: '/circuit',
          errorMessage: "Sorry we couldn't add the circuit.",
          successMessage: 'Your circuit has been added.'
        })
      },
      async updateCircuitList(circuitUid) {
        this.circuits = this.circuits.filter((circuit) => {
          return circuit.uid !== circuitUid
        })
      }
    }
  }
</script>
