<template>
  <Transition name="fade" appear>
    <OrganismCentered class="text-white bg-gray">
      <div class="m:w-full sm:mx-auto sm:max-w-md">
        <AtomIconHeader :title="title" :sub-title="subTitle" :classes="['text-black']" />
      </div>

      <Transition name="slide-scale" appear>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div class="p-6 bg-white rounded-lg">
            <MoleculeAuthForm :fields="form.fields" :cta="form.cta" :action="form.action" />
          </div>
        </div>
      </Transition>
    </OrganismCentered>
  </Transition>
</template>

<script>
  import AtomIconHeader from '@/components/atoms/IconHeader'

  export default {
    components: { AtomIconHeader },
    data() {
      return {
        subTitle: 'Welcome to Tracklimit, the UK’s first track day social media platform.',
        form: {
          cta: 'Create new account',
          action: 'auth/signUserUp',
          fields: [
            {
              id: 'email',
              label: 'Email Address',
              elem: 'input',
              type: 'email',
              isRequired: 'true'
            },
            {
              id: 'password',
              label: 'Password',
              elem: 'input',
              type: 'password',
              isRequired: 'true'
            }
          ]
        }
      }
    },
    computed: {
      account() {
        return this.$store.getters['auth/getAccount']
      }
    },
    watch: {
      account(user) {
        if (!!user) {
          this.$router.push({ name: 'Account Settings' })
        }
      }
    }
  }
</script>
