<template>
  <Transition name="fade" appear>
    <OrganismCentered class="text-white bg-gray">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <AtomIconHeader :title="title" :sub-title="subTitle" :classes="['text-black']" />
      </div>

      <Transition name="slide-scale" appear>
        <div class="mt-8 mx-auto w-full max-w-md">
          <div class="p-6 bg-white rounded-lg">
            <MoleculeAuthForm :fields="form.fields" :cta="form.cta" :title="form.title" :action="form.action" />
          </div>
        </div>
      </Transition>
    </OrganismCentered>
  </Transition>
</template>

<script>
  import AtomIconHeader from '@/components/atoms/IconHeader'

  export default {
    components: { AtomIconHeader },
    props: {
      email: {
        type: String,
        default: null
      },
      verified: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        subTitle: 'Log in to your account',
        form: {
          cta: 'Log in',
          action: 'auth/signUserIn',
          fields: [
            {
              id: 'email',
              label: 'Email Address',
              elem: 'input',
              type: 'email',
              isRequired: 'true',
              value: this.email
            },
            {
              id: 'password',
              label: 'Password',
              elem: 'input',
              type: 'password',
              isRequired: 'true'
            }
          ]
        }
      }
    },
    created() {
      if (this.verified) {
        this.$store.dispatch('setDialog', {
          title: 'Account Verified',
          content: 'Your account has been verified, you can now sign in',
          ctaTitle: 'Close',
          confirmed: true
        })
      }
    }
  }
</script>
